import React from 'react'
import PropTypes from 'prop-types'
import {stripHTML} from '../../../utils/utils'
import DynamicImage from '../../../components/dynamic-image'
import StarRating from '../../star-rating'
import Price from '../../price'

const ProductSuggestion = ({product, productsSfccGeneral}) => {
	if (!product) {
		return
	}

	const {attributes: productAttributes} = product
	const {
		image_link: image,
		web_url: url,
		TrustmateReviewsCount: [trustmateReviewsCount] = [0],
		TrustmateAverageGrade: [trustmateAverageGrade] = [0],
		'title.untouched': title,
		ShortDescription: description
	} = productAttributes

	const {priceObj, gtmProductData} = productsSfccGeneral ?? {}

	return (
		<a
			href={url}
			className="product-suggestions__item link js-gtm-search-box"
			data-action-field="sb"
			data-product-gtm={JSON.stringify(gtmProductData)}
		>
			<div className="product-suggestions__thumbnail">
				<DynamicImage
					src={image}
					imageType={'every_day_promo'}
					alt={title + '1'}
					isLazyLoad={false}
				/>
			</div>

			<div className="product-suggestions__content">
				<div className="product-suggestions__title">{stripHTML(title)}</div>
				<div className="product-suggestions__description">{description}</div>

				{window.ReactPreferences.REVIEWS_ENABLED && (
					<div className="product-suggestions__reviews">
						<StarRating
							showCount={true}
							modifier={'tile'}
							trustmateAverageGrade={Number(trustmateAverageGrade)}
							trustmateReviewsCount={Number(trustmateReviewsCount)}
						/>
					</div>
				)}

				<div className="product-suggestions__pricing">
					<Price price={priceObj} priceClass="suggestion" withLoyaltyPrice={false} />
				</div>
			</div>
		</a>
	)
}

ProductSuggestion.propTypes = {
	product: PropTypes.object,
	productsSfccGeneral: PropTypes.object
}

export default ProductSuggestion
