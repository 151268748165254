import React, {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import useScreenLocker from '../../hooks/use-screen-locker'

const Overlay = ({className, isOpen}) => {
	const activeClassName = 'overlay--active'
	const screenLocker = useScreenLocker()
	const [handleClose, setHandleClose] = useState(false)

	useEffect(() => {
		screenLocker.changeState(isOpen)
		if (isOpen) {
			setHandleClose(false)
		}
	}, [isOpen])

	const handleOverlayClick = () => {
		setHandleClose(true)
	}

	return (
		<>
			{createPortal(
				<div
					id="overlay"
					onClick={handleOverlayClick}
					className={`overlay ${className} ${isOpen && !handleClose && activeClassName}`}
				/>,
				document.getElementById('wrapper')
			)}
		</>
	)
}

Overlay.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool
}

export default Overlay
