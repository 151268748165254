import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import Icon from '../../icon'
import Tooltip from '../../tooltip'

const SupplierInformation = ({supplierInformation}) => {
	const intl = useIntl()
	const isSupplierInfoEnabled = window.ReactPreferences.IS_SUPPLIER_DISPLAY_INFO_ENABLED

	if (!supplierInformation || !isSupplierInfoEnabled) {
		return
	}

	let supplierLabel = 'product.product.supplier.label.regular'

	if (supplierInformation.isOfferSourceMarketplace) {
		supplierLabel = 'product.product.supplier.label.partner'
	}

	return (
		<div className="product-supplier-info">
			<span className="product-supplier-info__text">
				{intl.formatMessage({id: 'product.product.supplier.label'}) +
					' ' +
					intl.formatMessage({id: supplierLabel})}
			</span>

			<Tooltip
				triggerClass={'tooltip-trigger--info tooltip-trigger--supplier'}
				tooltipClass={'tooltip--supplier'}
				triggerContent={<Icon id={'icon-info'} />}
				arrow={false}
				place={'top'}
				spacing={4}
				clickOnly={true}
			>
				{supplierInformation.supplierInfoAsset}
			</Tooltip>
		</div>
	)
}

SupplierInformation.propTypes = {
	supplierInformation: PropTypes.object
}

export default SupplierInformation
