import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import CategoryRefinement from './category-refinement'
import useMediaQuery from '../../../hooks/use-media-query'
import {getFoldingModifier} from '../../../utils/search-utils'

const CategoryRefinements = ({
	filter,
	filterID,
	setRefinement,
	setOpenSecondaryPopUp,
	toggleFilter,
	selectedFilters
}) => {
	const intl = useIntl()
	const categories = filter?.values
	const isMobile = useMediaQuery('screen and (max-width: 1023px)')
	const initialFoldingValue = 10
	const categoriesLength = categories?.length || 0
	const [foldingCutoff, setFoldingCutoff] = useState(initialFoldingValue)
	const shouldBeFolded = categoriesLength > foldingCutoff
	const selectedFiltersAmount = selectedFilters?.length
	const [isFolded, setFolded] = useState(shouldBeFolded)
	const foldingTreshold = isFolded ? categoriesLength - foldingCutoff : 0

	const updateSelection = () => {
		setFolded(shouldBeFolded)
	}

	useEffect(() => {
		setFolded(shouldBeFolded)
	}, [shouldBeFolded])

	useEffect(() => {
		const refreshedFoldingModifier = getFoldingModifier(
			categories,
			selectedFilters,
			initialFoldingValue,
			1
		)
		setFoldingCutoff(initialFoldingValue + refreshedFoldingModifier)
	}, [selectedFilters])

	const handleMobileViewClick = () => {
		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		categories &&
		(isMobile ? (
			<div className={'refinement refinement-category_path'} onClick={handleMobileViewClick}>
				<div className={'refinement__listing'}>
					<div className="refinement-multi-categories">
						{categories.map((category, index) => {
							return (
								<CategoryRefinement
									category={category}
									toggleFilter={toggleFilter}
									selectedFilters={selectedFilters}
									key={category?.value + index}
									iteration={1}
									parentSelectionUpdate={updateSelection}
									hide={isFolded && index + 1 > initialFoldingValue}
								/>
							)
						})}

						{isFolded && (
							<div
								className="refinement-multi-categories__show-all"
								onClick={() => setFolded(!isFolded)}
							>
								{intl.formatMessage({
									id: 'search.search.show.more'
								})}
								{' (' + foldingTreshold + ')'}
							</div>
						)}
					</div>
				</div>

				<div className="refinement__title">
					<span className="js-gtm-ref-name">
						{intl.formatMessage({
							id: 'search.search.refinement.title.category'
						})}
					</span>
					{selectedFiltersAmount > 0 && (
						<span className="refinement__number">
							{selectedFiltersAmount.toString()}
						</span>
					)}
					<span className="refinement__title-arrow"></span>
				</div>
			</div>
		) : (
			<div className="refinement-multi-categories" onClick={handleMobileViewClick}>
				{categories.map((category, index) => {
					return (
						<CategoryRefinement
							category={category}
							toggleFilter={toggleFilter}
							selectedFilters={selectedFilters}
							key={category?.value + index}
							iteration={1}
							parentSelectionUpdate={updateSelection}
							hide={isFolded && index + 1 > initialFoldingValue}
						/>
					)
				})}

				{isFolded && (
					<div
						className="refinement-multi-categories__show-all"
						onClick={() => setFolded(!isFolded)}
					>
						{intl.formatMessage({
							id: 'search.search.show.more'
						})}
						{' (' + foldingTreshold + ')'}
					</div>
				)}
			</div>
		))
	)
}

CategoryRefinements.propTypes = {
	filter: PropTypes.object,
	selectedFilters: PropTypes.array,
	filterID: PropTypes.string,
	setRefinement: PropTypes.func,
	setOpenSecondaryPopUp: PropTypes.func,
	toggleFilter: PropTypes.func
}

export default CategoryRefinements
