import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'
import {useIntl} from 'react-intl'

const LinkRefinements = ({
	filter,
	filterID,
	setRefinement,
	classExtensions,
	setOpenSecondaryPopUp,
	toggleFilter,
	selectedFilters
}) => {
	const intl = useIntl()
	const [isToggled, setToggle] = useState(false)
	const selectedFiltersAmount = selectedFilters?.length
	const componentRef = useRef(null)

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleMobileViewClick = () => {
		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		<div
			className={
				'refinement refinement-' + filter.name + (isToggled && ' opened ') + classExtensions
			}
			onClick={handleMobileViewClick}
			ref={componentRef}
		>
			<div className={'refinement__listing'}>
				<ul>
					{filter.values.map((value) => {
						const isSelected = selectedFilters.includes(value.value)
						return (
							<li
								className={'refinement__list' && isSelected && ' selected'}
								key={value.value}
							>
								<a onClick={() => toggleFilter(value, filter.name, isSelected)}>
									<span className="refinement__label-text">
										{value.value.slice(0, 1).toUpperCase() +
											value.value.slice(1, value.value.length)}
									</span>
									<span className="refinement__label-count">
										({value.hits_count})
									</span>

									{isSelected && (
										<Icon
											className="refinement-price__selected-icon"
											id={'icon-success-cyrcle'}
										/>
									)}
								</a>
							</li>
						)
					})}
				</ul>
			</div>

			<div className="refinement__title" onClick={() => setToggle(!isToggled)}>
				<span className="js-gtm-ref-name">
					{intl.formatMessage({
						id: 'search.search.refinement.title.custom'
					})}
				</span>
				{selectedFiltersAmount > 0 && (
					<span className="refinement__number">{selectedFiltersAmount.toString()}</span>
				)}
				<span className="refinement__title-arrow"></span>
			</div>
		</div>
	)
}

LinkRefinements.propTypes = {
	filter: PropTypes.object,
	selectedFilters: PropTypes.array,
	filterID: PropTypes.string,
	setRefinement: PropTypes.func,
	classExtensions: PropTypes.string,
	setOpenSecondaryPopUp: PropTypes.func,
	toggleFilter: PropTypes.func
}

export default LinkRefinements
