import {useCallback, useRef} from 'react'

const listener = () => {
	window.ScreenLocker.corruptedScroll = false
	window.removeEventListener('scroll', listener)
}

/**
 * Custom React hook to get the function that adds ScreenLocker functionality which allows to prevent not necessary user interactions
 * @returns {{changeState: (function(Boolean): *)}} changeState - Function that changes the screen lock state
 */
export const useScreenLocker = () => {
	window.ScreenLocker = window.ScreenLocker || {}
	const target = document.documentElement
	const body = document.body
	const lockedClassName = 'ui-screen-locked'
	let lastScrollY = useRef(0)

	const isLocked = () => {
		return target.classList.contains(lockedClassName)
	}

	const changeState = useCallback((state) => {
		const hadLock = isLocked()
		let scrollWidth = 0

		if (!hadLock && state) {
			lastScrollY = window.scrollY
			const offset = -lastScrollY

			target.style.top = `${offset}px`
			scrollWidth = window.innerWidth - target.clientWidth
			body.style.paddingRight = `${scrollWidth}px`
		}

		window.ScreenLocker.corruptedScroll = true
		window.addEventListener('scroll', listener)

		target.classList[state ? 'add' : 'remove'](lockedClassName)

		const event = new CustomEvent('screenlocker:change', {detail: state})
		document.dispatchEvent(event)

		if (hadLock && !state) {
			target.style.top = ''
			body.style.paddingRight = ''
			window.scrollTo(0, lastScrollY)
		}

		document.documentElement.style.setProperty('--scroll-width', `${scrollWidth}px`)
	}, [])

	return {changeState}
}

export default useScreenLocker
