import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import EmptySearchResults from './partials/empty-results'
import SelectedRefinements from './partials/selected-refinements'
import PageHeader from './partials/page-header'
import {useSearchParams} from '../../hooks/use-search-params'
import useNavigation from '../../hooks/use-navigation'
import useProductSearch from '../../hooks/use-product-search'
import useContentAsset from '../../hooks/use-content-asset'
import {useWishList} from '../../hooks/use-wish-list'
import useProductsAvailability from '../../hooks/use-products-availability'
import useProductsSfccGeneral from '../../hooks/use-products-sfcc-general'
import usePageUrls from '../../hooks/use-page-urls'
import {useCurrency} from '../../hooks/use-currency'
import Icon from '../../components/icon'
import TopRefinements from './partials/top-refinements'
import SideRefinements from './partials/side-refinements'
import ProductTile, {Skeleton as ProductTileSkeleton} from '../../components/product-tile'
import Pagination from '../../components/pagination'
import useMediaQuery from '../../hooks/use-media-query'
import Tooltip from '../../components/tooltip'
import {
	excludeCategoryRefinements,
	separateCategoryFacets,
	extractRatingNumber,
	getFacetIDByDisplayName,
	filterOutPartialCategoryRefinements
} from '../../utils/refinement-utils'
import {scrollToElement} from '../../utils/utils'
import {useLocation, useParams} from 'react-router-dom'

const ProductList = () => {
	const intl = useIntl()
	const location = useLocation()
	const {currency} = useCurrency()
	const navigate = useNavigation()
	const urlParams = new URLSearchParams(location.search)
	const searchQuery = urlParams.get('q')
	const isMobile = useMediaQuery('screen and (max-width: 1023px)')
	const params = useParams()
	const [searchParams, {stringify: stringifySearchParams}] = useSearchParams()
	const wishlist = useWishList()
	const stringifiedSearchParams = stringifySearchParams(searchParams)

	if (params.categoryId) {
		searchParams._refine.push(`cgid=${params.categoryId}`)
	}

	const {
		isLoading,
		isRefetching,
		data: productSearchResult
	} = useProductSearch(
		{
			parameters: {
				...searchParams,
				refine: searchParams._refine
			}
		},
		{
			keepPreviousData: true
		}
	)

	const basePath = `${location.pathname}${location.search}`
	const showNoResults =
		!isLoading &&
		!isRefetching &&
		productSearchResult &&
		!productSearchResult?.results?.hits.length
	const total = productSearchResult?.results?.total_hits

	const productIDs = productSearchResult?.results?.hits.map((el) => {
		return el.attributes.ID[0]
	})
	const productsAvailability = useProductsAvailability(productIDs)
	const {data: productsSfccGeneral} = useProductsSfccGeneral(productIDs)

	const pageUrls = usePageUrls({total})

	const {data: contentAsset} = useContentAsset('order-of-products-information')

	// Toggles filter on and off
	const toggleFilter = (value, attributeId, selected) => {
		const searchParamsCopy = {...searchParams}

		// Remove the `offset` search param if present.
		delete searchParamsCopy.offset

		// Get the attibute value as an array.
		let attributeValue = searchParamsCopy.refine[attributeId] || []

		if (attributeValue === true) {
			attributeValue = attributeValue.toString()
		}

		// Ensure that the value is still converted into an array if it's a `string` or `number`.
		if (typeof attributeValue === 'string') {
			attributeValue = attributeValue.split('|')
		} else if (typeof attributeValue === 'number') {
			attributeValue = [attributeValue]
		}

		if (attributeId == 'price') {
			attributeValue = []
		}

		if (attributeId === 'category') {
			if (selected) {
				attributeValue = excludeCategoryRefinements(attributeValue, value.value)
			} else {
				attributeValue = filterOutPartialCategoryRefinements(attributeValue, value.value)
			}
		}

		// Either set the value, or filter the value out.
		if (!selected) {
			attributeValue.push(value.value)
		} else {
			// Note the loose comparison, for "string != number" checks.
			attributeValue = attributeValue?.filter((v) => v != value.value)
		}

		// Update the attribute value in the new search params.
		searchParamsCopy.refine[attributeId] = attributeValue

		// If the update value is an empty array, remove the current attribute key.
		if (searchParamsCopy.refine[attributeId].length === 0) {
			delete searchParamsCopy.refine[attributeId]
		}

		navigate(`/search?${stringifySearchParams(searchParamsCopy)}`)
		scrollToElement('.search-results')
	}

	// Clears all filters
	const resetFilters = () => {
		const newSearchParams = {
			...searchParams,
			refine: []
		}
		const newPath = `/search?${stringifySearchParams(newSearchParams)}`

		navigate(newPath)
	}

	const [openRefinementPopUp, setOpenRefinementPopUp] = useState(false)
	const [openSortingPopUpState, setOpenSortingPopUp] = useState(false)
	const [eventTriggered, setEventTriggered] = useState(false)
	const [submittedSearchQuery, setSubmittedSearchQuery] = useState('')

	useEffect(() => {
		setEventTriggered(false)

		if (showNoResults) {
			setSubmittedSearchQuery(searchQuery)
		}
	}, [stringifiedSearchParams, showNoResults])

	useEffect(() => {
		if (!isLoading && !isRefetching && productSearchResult && productsSfccGeneral) {
			if (!eventTriggered) {
				const event = new Event('searchResultProductPriningCompleted')
				document.dispatchEvent(event)
				setEventTriggered(true)
			} else {
				setSubmittedSearchQuery(searchQuery)

				if (submittedSearchQuery !== searchQuery) {
					const event = new Event('searchResultGtmQuery')
					document.dispatchEvent(event)
				}
			}
		}
	}, [isLoading, isRefetching, productSearchResult, productsSfccGeneral, eventTriggered])

	const [categoryFacet, filteredFacets] = separateCategoryFacets(
		productSearchResult?.results?.facets,
		isMobile
	)

	const parseFilter = (keyString, filter) => {
		if (keyString === 'price') {
			return filter.split('|').map(
				(val, index) =>
					intl.formatNumber(val, {
						style: 'currency',
						currency: currency
					}) + (index == 0 ? ' - ' : '')
			)
		}

		if (keyString === 'category') {
			return filter.replace(/\|\|/g, ' > ')
		}

		if (keyString === 'trustmateAverageGrade') {
			filter = extractRatingNumber(filter)
			return intl.formatMessage({
				id: 'reviews.review.stars' + (filter - 1)
			})
		}

		if (filter === 'true') {
			return keyString
		}

		return filter
	}

	const selectedFilters = []
	const selectedFilterValues = productSearchResult?.results?.filters

	selectedFilterValues?.forEach((item) => {
		const split = item.split(':')
		const filter = split[1]
		const keyString = split[0]

		if (filter == 'item' || keyString == 'searchable') {
			return
		}

		var refinementID = getFacetIDByDisplayName(keyString) || keyString

		const selected = {
			uiLabel: parseFilter(refinementID, filter),
			value: refinementID,
			apiLabel: filter
		}

		selectedFilters.push(selected)
	})

	const selectedFiltersAmount = selectedFilters?.length
	const selectedFiltersParams = searchParams.refine

	return (
		searchQuery && (
			<>
				{showNoResults ? (
					<EmptySearchResults searchQuery={searchQuery} />
				) : (
					<div className="section-row">
						<PageHeader
							searchQuery={searchQuery}
							productSearchResult={productSearchResult}
							isLoading={isLoading}
						/>

						<div className="search-results">
							<div className="search-results__sidebar">
								<SideRefinements
									toggleFilter={toggleFilter}
									filters={categoryFacet}
									selectedFilters={selectedFiltersParams}
									openRefinementPopUpState={openRefinementPopUp}
									setOpenSortingPopUp={setOpenSortingPopUp}
									openSortingPopUpState={openSortingPopUpState}
									setOpenRefinementPopUp={setOpenRefinementPopUp}
									productSearchResult={productSearchResult}
									basePath={basePath}
									selectedFiltersFormatted={selectedFilters}
									handleReset={resetFilters}
								/>
							</div>
							<div className="search-results__products">
								{!isMobile && (
									<div className="search-results__topbar">
										<TopRefinements
											toggleFilter={toggleFilter}
											filters={filteredFacets}
											selectedFilters={selectedFiltersParams}
											basePath={basePath}
										/>
									</div>
								)}

								<div className="category-controls">
									<div className="category-controls__buttons">
										<div
											className="category-controls__trigger-button"
											onClick={() =>
												setOpenRefinementPopUp(!openRefinementPopUp)
											}
										>
											<Icon className={''} id={'icon-interface-adjust'} />
											{intl.formatMessage({
												id: 'search.global.searchrefinement.filter'
											})}
											{selectedFiltersAmount > 0 && (
												<div className="category-controls__refinements-count">
													{selectedFiltersAmount.toString()}
												</div>
											)}
										</div>

										<div
											className="category-controls__trigger-button"
											onClick={() =>
												setOpenSortingPopUp(!openSortingPopUpState)
											}
										>
											<Icon className={''} id={'icon-interface-sort'} />
											{intl.formatMessage({
												id: 'search.searchrefinebar.sorting'
											})}
										</div>
									</div>

									<div className="category-controls__total-items js-search-total-items">
										{intl.formatMessage({id: 'product.product.count.text'})}

										<span className="category-controls__items-count">
											{productSearchResult?.results?.total_hits}
										</span>

										<Tooltip
											triggerClass={'tooltip-trigger--info'}
											tooltipClass={'tooltip--info'}
											triggerContent={<Icon id={'icon-info'} />}
											arrow={false}
											place={'top'}
											spacing={4}
											clickOnly={true}
										>
											{contentAsset}
										</Tooltip>
									</div>

									<div className="category-controls__refinements">
										<SelectedRefinements
											filters={productSearchResult?.results?.facets}
											toggleFilter={toggleFilter}
											handleReset={resetFilters}
											selectedFilters={selectedFilters}
										/>
									</div>
								</div>

								<div className="search-result-content">
									<ul className="product-grid tiles-container">
										{isRefetching ||
										!productSearchResult ||
										!productsSfccGeneral
											? new Array(searchParams.limit)
													.fill(0)
													.map((value, index) => (
														<ProductTileSkeleton key={index} />
													))
											: productSearchResult?.results?.hits?.map(
													(productSearchItem, index) => {
														const productId =
															productSearchItem.attributes.ID[0]

														return (
															<ProductTile
																key={productId}
																product={productSearchItem}
																index={index}
																productAvailability={
																	productsAvailability?.[index]
																}
																productsSfccGeneral={
																	productsSfccGeneral?.[index]
																}
																isFavourite={
																	wishlist.indexOf(productId) > -1
																}
															/>
														)
													}
												)}
									</ul>
									<Pagination currentURL={basePath} urls={pageUrls} />
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		)
	)
}

export default ProductList
