import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../../hooks/use-currency'
import {sortPriceRefinements} from '../../../utils/refinement-utils'
import Icon from '../../../components/icon'

const PriceRefinements = ({
	filter,
	filterID,
	setRefinement,
	classExtensions,
	setOpenSecondaryPopUp,
	toggleFilter,
	selectedFilters
}) => {
	const intl = useIntl()
	const {currency} = useCurrency()
	const [isToggled, setToggle] = useState(false)
	const selectedFiltersFormatted = selectedFilters.join('|')
	const priceValues = sortPriceRefinements(filter?.values)
	const selectedFiltersAmount = selectedFilters?.length - 1
	const componentRef = useRef(null)
	const filterName = (value) => {
		return value.split('|').map(
			(val, index) =>
				intl.formatNumber(val, {
					style: 'currency',
					currency: currency
				}) + (index == 0 ? ' - ' : '')
		)
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleMobileViewClick = (e) => {
		e.preventDefault()
		e.stopPropagation()

		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		<div
			className={
				'refinement refinement-price ' +
				(isToggled && ' opened ') +
				(classExtensions && classExtensions)
			}
			onClick={handleMobileViewClick}
			ref={componentRef}
		>
			<div className={'refinement-price__listing'}>
				<ul>
					{priceValues
						.filter((refinementValue) => refinementValue.hits_count > 0)
						.map((value) => {
							const isChecked = selectedFiltersFormatted.includes(value.value)

							return (
								<li
									key={value.value}
									className={
										'refinement-price__list ' + (isChecked && ' selected')
									}
									onClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
										toggleFilter(
											value,
											'price',
											selectedFiltersFormatted.includes(value.value)
										)
									}}
									data-gtm-value={filterName(value.value)}
								>
									<a>
										<input
											className="input-radio"
											type="radio"
											name="price"
											value={value.value}
											id={value.value}
											checked={isChecked}
											onChange={() => {
												toggleFilter(
													value,
													'price',
													selectedFiltersFormatted.includes(value.value)
												)
											}}
										/>
										<label
											className="radio-label refinement-price__text"
											htmlFor={value.value}
										>
											<span className="refinement-price__label-text">
												{' '}
												{filterName(value.value)}{' '}
											</span>
											<span className="refinement-price__label-count">
												({value.hits_count})
											</span>
										</label>
										{isChecked && (
											<Icon
												className="refinement-price__selected-icon"
												id={'icon-success-cyrcle'}
											/>
										)}
									</a>
								</li>
							)
						})}
				</ul>
			</div>

			<div className="refinement__title" onClick={() => setToggle(!isToggled)}>
				<span className="js-gtm-ref-name">
					{intl.formatMessage({
						id: 'search.search.refinement.title.price'
					})}
				</span>
				{selectedFiltersAmount > 0 && (
					<span className="refinement__number">{selectedFiltersAmount.toString()}</span>
				)}
				<span className="refinement__title-arrow"></span>
			</div>
		</div>
	)
}

PriceRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	filterID: PropTypes.string,
	setRefinement: PropTypes.func,
	classExtensions: PropTypes.string,
	setOpenSecondaryPopUp: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default PriceRefinements
