import {useEffect} from 'react'
import {getPopularSearch} from '../services/lbx-service'

const usePopularSearch = () => {
	const storedResult = sessionStorage.getItem('popularSearchResult')
	const popularSearchResult = storedResult ? JSON.parse(storedResult) : null

	useEffect(() => {
		if (!popularSearchResult) {
			const fetchPopularSearch = async () => {
				const result = (await getPopularSearch()) || []
				sessionStorage.setItem('popularSearchResult', JSON.stringify(result))
			}

			fetchPopularSearch()
		}
	}, [popularSearchResult])

	return {popularSearchResult}
}

export default usePopularSearch
