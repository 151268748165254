import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import Icon from '../../../components/icon'
import useMediaQuery from '../../../hooks/use-media-query'
import useNavigation from '../../../hooks/use-navigation'
import {useSearchParams} from '../../../hooks/use-search-params'

const BrandRefinements = ({
	filter,
	filterID,
	classExtensions,
	toggleFilter,
	setRefinement,
	setOpenSecondaryPopUp,
	selectedFilters
}) => {
	const intl = useIntl()
	const [searchText, setSearchText] = useState('')
	const [isCleared, setIsCleared] = useState(false)

	const isMobile = useMediaQuery('screen and (max-width: 1023px)')
	const navigate = useNavigation()
	const brandFilters = new Set()
	const [searchParams, {stringify: stringifySearchParams}] = useSearchParams()
	const showCloseIcon = !!searchText
	const componentRef = useRef(null)
	const selectedFiltersAmount = selectedFilters?.length
	const [isDisabledSubmit, setIsDisabledSubmit] = useState(selectedFiltersAmount > 0)
	const inputsRef = useRef([])
	const getFilteredArr = () => {
		const map = new Map()
		var arr = filter?.values.filter((item) => {
			return item.value.toLowerCase().indexOf(searchText) !== -1
		})
		arr.sort((a, b) => a.value.localeCompare(b.value)).forEach((value) => {
			!map.has(value.value[0])
				? map.set(value.value[0], [value])
				: map.get(value.value[0]).push(value)
		})

		return [...map]
	}

	const filteredBrands = getFilteredArr()
	const [isToggled, setToggle] = useState(false)

	const getFilterValues = () => {
		brandFilters.clear()

		const checkedBrands = Object.keys(checkedItems)

		checkedBrands.forEach((brand) => {
			if (checkedItems[brand] === true) {
				brandFilters.add(brand)
			}
		})

		if (!brandFilters) {
			return ''
		}

		return [...brandFilters]
	}

	const toggleFilters = (attributeId) => {
		const searchParamsCopy = {...searchParams}

		// Remove the `offset` search param if present.
		delete searchParamsCopy.offset

		// Get the attibute value as an array.
		const attributeValues = getFilterValues()

		// Update the attribute value in the new search params.
		searchParamsCopy.refine[attributeId] = attributeValues

		// If the update value is an empty array, remove the current attribute key.
		if (searchParamsCopy.refine[attributeId].length === 0) {
			delete searchParamsCopy.refine[attributeId]
		}

		navigate(`/search?${stringifySearchParams(searchParamsCopy)}`)
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const [checkedItems, setCheckedItems] = useState({})

	const handleWrapperClick = (id) => {
		setCheckedItems((prevState) => ({
			...prevState,
			[id]: !prevState[id]
		}))
		setIsCleared(false)
		setIsDisabledSubmit(false)
	}

	useEffect(() => {
		const initialChecked = {}

		selectedFilters.forEach((brandName) => {
			initialChecked[brandName] = true
		})

		setCheckedItems(initialChecked)
	}, [selectedFilters])

	const handleMobileViewClick = () => {
		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		<div
			className={'refinement refinement-brand ' + (isToggled && ' opened ') + classExtensions}
			onClick={handleMobileViewClick}
			ref={componentRef}
		>
			<div className={'refinement-brand__listing'}>
				<div
					id="refinement-by-brand"
					className="refinement-brand__search js-refinement-brand"
				>
					<Icon
						id={'icon-search'}
						className={'refinement-brand__icon refinement-brand__icon--visible'}
					/>

					<input
						type="text"
						className="refinement-brand__input js-refinement-brand-input"
						id="brandSearch"
						name="brandSearch"
						value={searchText}
						onChange={({target}) => setSearchText(target.value)}
						placeholder={intl.formatMessage({
							id: 'search.global.refinements.refinementbybrand'
						})}
						autoComplete="off"
					/>

					<Icon
						id={'icon-closethin'}
						className={
							'refinement-brand__icon ' +
							(showCloseIcon && 'refinement-brand__icon--clear')
						}
						onClick={() => setSearchText('')}
					/>
				</div>

				<ul>
					{filteredBrands.map(([key, values]) => {
						return (
							<React.Fragment key={key}>
								{values.map((value, index) => {
									let isChecked = !!checkedItems[value.value] && !isCleared
									return (
										<li
											className={
												'refinement__list refinement-brand__list ' +
												(isChecked && ' selected')
											}
											data-gtm-value={value.value}
											onClick={() => handleWrapperClick(value.value)}
											key={value.value}
										>
											<a
												className="refinement-brand__link"
												title={
													intl.formatMessage({
														id: 'search.search.productsearchrefinebar.clickrefine'
													}) + value.value
												}
											>
												<input
													className="input-checkbox"
													ref={(el) => (inputsRef.current[index] = el)}
													id={value.value + '-brand'}
													type="checkbox"
													value={value.value}
													onChange={() => {
														if (!isMobile) {
															return
														}

														toggleFilter(value, filter.name, isChecked)
													}}
													checked={isChecked}
												/>

												<label
													className="checkbox-label"
													htmlFor={value.value + '-brand'}
												>
													<span className="refinement-brand__label-text">
														{value.value}{' '}
													</span>
													<span className="refinement-brand__label-count">
														({value.hits_count})
													</span>
												</label>
												{isChecked && (
													<Icon
														className="refinement-brand__selected-icon"
														id={'icon-success-cyrcle'}
													/>
												)}
											</a>
										</li>
									)
								})}
							</React.Fragment>
						)
					})}
				</ul>

				<div className="refinement-brand__controls">
					<button
						className="button button--outline refinement-brand__clear-btn"
						onClick={() => {
							setIsCleared(true)
							setIsDisabledSubmit(false)
							setCheckedItems({})
						}}
					>
						{intl.formatMessage({
							id: 'search.searchrefinebar.clearselection'
						})}
					</button>

					<button
						className={
							'button refinement-brand__submit-btn ' +
							(isDisabledSubmit && ' disabled')
						}
						onClick={() => toggleFilters(filter.name)}
					>
						{intl.formatMessage({
							id: 'search.searchrefinebar.saveselection'
						})}
					</button>
				</div>
			</div>

			<div className="refinement__title" onClick={() => setToggle(!isToggled)}>
				<span className="js-gtm-ref-name">
					{intl.formatMessage({
						id: 'search.search.refinement.title.brand'
					})}
				</span>
				{selectedFiltersAmount > 0 && (
					<span className="refinement__number">{selectedFiltersAmount.toString()}</span>
				)}
				<span className="refinement__title-arrow"></span>
			</div>
		</div>
	)
}

BrandRefinements.propTypes = {
	filter: PropTypes.object,
	filterID: PropTypes.string,
	classExtensions: PropTypes.string,
	setRefinement: PropTypes.func,
	toggleFilter: PropTypes.func,
	setOpenSecondaryPopUp: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default BrandRefinements
