import React, {useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'

/* Provide main overlay functionality along with ScreenLocker hook
 */
const Layout = ({children, state}) => {
	const className = 'main-overlay'
	const activeClassName = `${className}--opened`
	const rootEl = document.getElementById('layout')
	const layoutRef = useRef(rootEl)

	const resize = () => {
		layoutRef.current.style.height =
			document.body.scrollHeight -
			layoutRef.current.getBoundingClientRect().top -
			window.scrollY +
			'px'
	}

	useEffect(() => {
		layoutRef.current.classList[state ? 'add' : 'remove'](activeClassName)

		if (state) {
			resize()
		}
	}, [state])

	return (
		<>
			{createPortal(
				<div
					id="layout"
					className={className + ' ' + (state && activeClassName)}
					ref={layoutRef}
				>
					{children}
				</div>,
				document.getElementById('main')
			)}
		</>
	)
}

Layout.propTypes = {
	state: PropTypes.bool,
	children: PropTypes.node
}

export default Layout
