import React from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'

const SelectedRefinements = ({toggleFilter, selectedFilters, handleReset}) => {
	const intl = useIntl()

	return (
		<React.Fragment>
			{selectedFilters?.length > 0 && (
				<div className="refinement-pill">
					<div className="refinement-pill__clear-text">
						{intl.formatMessage({
							id: 'search.searchrefinebar.used.filters.label'
						})}
					</div>
					<div className="refinement-pill__row">
						{selectedFilters?.map((filter, idx) => {
							return (
								<div
									className="refinement-pill__item"
									title={intl.formatMessage({
										id: 'locale.global.remove.refine'
									})}
									key={idx}
									onClick={() =>
										toggleFilter({value: filter.apiLabel}, filter.value, true)
									}
								>
									<span className="refinement-pill__value">{filter.uiLabel}</span>
									<div className="refinement-pill__icon">
										<Icon id={'icon-closethick'} />
									</div>
								</div>
							)
						})}

						<div className="refinement-pill__clear">
							<span className="link link--hover" onClick={handleReset}>
								<Icon
									className={'refinement-pill__clear-icon'}
									id={'icon-closethick'}
								/>
								{intl.formatMessage({
									id: 'search.searchrefinebar.clearselectedfilters'
								})}
							</span>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

SelectedRefinements.propTypes = {
	filters: PropTypes.array,
	selectedFilters: PropTypes.array,
	toggleFilter: PropTypes.func,
	handleReset: PropTypes.func
}

export default SelectedRefinements
