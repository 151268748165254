import React, {useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {sortRatingRefinements, extractRatingNumber} from '../../../utils/refinement-utils'
import {useIntl} from 'react-intl'
import Icon from '../../../components/icon'

const RatingRefinements = ({
	filter,
	filterID,
	classExtensions,
	setRefinement,
	setOpenSecondaryPopUp,
	toggleFilter,
	selectedFilters
}) => {
	const intl = useIntl()
	const ratingValues = sortRatingRefinements(filter?.values)
	const [isToggled, setToggle] = useState(false)
	const selectedFiltersAmount = selectedFilters?.length
	const componentRef = useRef(null)

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleMobileViewClick = () => {
		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		<div
			className={
				'refinement refinement-trustmateAverageGrade ' +
				(isToggled && ' opened ') +
				classExtensions
			}
			onClick={handleMobileViewClick}
			ref={componentRef}
		>
			<div className={'refinement__listing'}>
				<ul>
					{ratingValues.map((value) => {
						const isChecked = selectedFilters.includes(value.value)
						return (
							<li
								className={'refinement__list ' + (isChecked && ' selected')}
								key={value.value}
								data-gtm-value={value.value}
							>
								<div className="refinement__item">
									<input
										className="input-checkbox"
										id={value.value}
										type="checkbox"
										checked={isChecked}
										onChange={() =>
											toggleFilter(value, 'trustmateAverageGrade', isChecked)
										}
									/>

									<label className="checkbox-label" htmlFor={value.value}>
										<div className="reviews-stars reviews-stars--refinement">
											<div className="reviews-stars__count">
												{value.value}
											</div>
											<div className="reviews-stars__star">
												<span
													className="reviews-stars__value"
													style={{
														width: `${(extractRatingNumber(value.value) / 5) * 100}%`
													}}
												></span>
											</div>
										</div>
										<span className="refinement__label-count">
											({value.hits_count})
										</span>
									</label>
									{isChecked && (
										<Icon
											className="refinement__selected-icon"
											id={'icon-success-cyrcle'}
										/>
									)}
								</div>
							</li>
						)
					})}
				</ul>
			</div>

			<div
				className="refinement__title refinement-rating__title"
				onClick={() => setToggle(!isToggled)}
			>
				<span className="js-gtm-ref-name">
					{intl.formatMessage({
						id: 'search.search.refinement.title.trustmateAverageGrade'
					})}
				</span>
				{selectedFiltersAmount > 0 && (
					<span className="refinement__number">{selectedFiltersAmount.toString()}</span>
				)}
				<span className="refinement__title-arrow"></span>
			</div>
		</div>
	)
}

RatingRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	filterID: PropTypes.string,
	setRefinement: PropTypes.func,
	classExtensions: PropTypes.string,
	setOpenSecondaryPopUp: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default RatingRefinements
