import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import {createPortal} from 'react-dom'
import {breakpoints} from '../../config/breakpoints'
import Overlay from '../overlay'
import Icon from '../icon'
import {useIntl} from 'react-intl'

let activeTooltip = null
const Tooltip = ({
	children,
	triggerContent,
	triggerClass = '',
	tooltipClass = '',
	autoPositioning = true,
	place = 'top-right',
	offset = 45,
	spacing = 10,
	arrow = true,
	clickOnly = false,
	desktopOnly = false
}) => {
	const handler = clickOnly ? 'onClick' : 'onMouseEnter'
	const intl = useIntl()
	const tooltipRef = useRef(null)
	const tooltipTriggerRef = useRef(null)
	const tooltipArrowRef = useRef(null)
	const hideTimeoutRef = useRef(null)
	const eventHandlersRef = useRef({})
	const [isOpen, setOpen] = useState(false)
	const [arrowLeftAdjust, setArrowLeftAdjust] = useState(null)
	const [tooltipStyle, setTooltipStyle] = useState({})
	const [arrowStyle, setArrowStyle] = useState({})
	const DOMRect = document.documentElement.getBoundingClientRect()
	const isSmallScreen = window.innerWidth < breakpoints['bp-small-medium']
	let height = 0
	let width = 0

	const handleClose = useCallback((e) => {
		if (e) e.stopPropagation()
		setOpen(false)
		if (activeTooltip === handleClose) {
			activeTooltip = null
		}
	}, [])

	const handleOpen = (e) => {
		if (e) e.stopPropagation()
		if (isOpen || (desktopOnly && isSmallScreen)) return

		if (activeTooltip && activeTooltip !== handleClose) {
			activeTooltip(e)
		}

		handleCancelClose()
		setOpen(true)
		activeTooltip = handleClose
	}

	const arrowPosition = () => {
		if (!arrow) {
			return
		}

		let top = 0
		let left = 0
		const target = tooltipTriggerRef.current.getBoundingClientRect()
		const arrowDimension = tooltipArrowRef.current.getBoundingClientRect()

		switch (place) {
			case 'top':
				top = height - arrowDimension.height / 2
				left = width / 2 - arrowDimension.width / 2
				break
			case 'top-right':
				top = height - arrowDimension.height / 2
				left = offset - arrowDimension.width / 2 + target.width / 2
				break
			case 'top-left':
				top = height - arrowDimension.height / 2
				left = width - offset - target.width / 2 - arrowDimension.width / 2
				break
			case 'bottom':
				top = -arrowDimension.height / 2
				left = width / 2 - arrowDimension.width / 2
				break
			case 'bottom-left':
				top = -arrowDimension.height / 2
				left = width - offset - target.width / 2 - arrowDimension.width / 2
				break
			case 'bottom-right':
				top = -arrowDimension.height / 2
				left = offset - arrowDimension.width / 2 + target.width / 2
				break
			// no default
		}

		if (arrowLeftAdjust) {
			left = left - arrowLeftAdjust
		}

		setArrowStyle({
			top: Math.round(top) + 'px',
			left: Math.round(left + 1) + 'px'
		})
	}

	const pickPlace = () => {
		if (!autoPositioning) {
			return place
		}

		const placeResult = place.split('-')
		const target = tooltipTriggerRef.current.getBoundingClientRect()
		const winPos = {
			bottom: DOMRect.bottom,
			top: DOMRect.top,
			left: DOMRect.left,
			right: DOMRect.right,
			height: DOMRect.height,
			width: DOMRect.width
		}
		winPos.right = winPos.width - winPos.left - target.width
		winPos.bottom = winPos.height - winPos.top - target.height

		if (target.top - height - spacing <= winPos.top) {
			placeResult[0] = 'bottom'
		} else if (target.bottom + height + spacing >= winPos.bottom) {
			placeResult[0] = 'top'
		} else if (width > winPos.width) {
			placeResult.splice(1)
		}

		if (width + offset > winPos.width) {
			return placeResult[0]
		}

		switch (placeResult[1]) {
			case 'left':
				if (target.right - width <= winPos.left) placeResult[1] = 'right'
				break
			case 'right':
				if (target.left + width >= winPos.right) placeResult[1] = 'left'
				break
			default:
				if (target.left + target.width / 2 + width / 2 >= winPos.right)
					placeResult[1] = 'left'
				else if (target.right - target.width / 2 - width / 2 <= winPos.left)
					placeResult[1] = 'right'
		}

		return placeResult.join('-')
	}

	const position = () => {
		const newPlace = pickPlace()
		const target = tooltipTriggerRef.current.getBoundingClientRect()
		let top = 0
		let left = 0

		if (newPlace !== place) {
			place = newPlace
		}

		switch (place) {
			case 'top':
				top = target.top - height - spacing
				left = target.left + target.width / 2 - width / 2
				break
			case 'top-left':
				top = target.top - height - spacing
				left = target.right - width + offset
				break
			case 'top-right':
				top = target.top - height - spacing
				left = target.left - offset
				break
			case 'bottom':
				top = target.bottom + spacing
				left = target.left + target.width / 2 - width / 2
				break
			case 'bottom-left':
				top = target.bottom + spacing
				left = target.right - width + offset
				break
			case 'bottom-right':
				top = target.bottom + spacing
				left = target.left - offset
				break
			// no default
		}

		if (autoPositioning) {
			const leftOffset = left + width + 16

			if (leftOffset > DOMRect.width) {
				const newLeftPos = leftOffset - DOMRect.width
				setArrowLeftAdjust(-newLeftPos)
				left = left - newLeftPos
			}

			if (left < 16) {
				setArrowLeftAdjust(Math.abs(left - 16))
				left = 16
			}
		}

		setTooltipStyle({
			top: Math.round(top) + 'px',
			left: Math.round(left) + 'px'
		})
	}

	const wrapHandler = (key, func) => {
		if (!eventHandlersRef.current[key]) {
			eventHandlersRef.current[key] = (event) => {
				if (event.type !== 'scroll') {
					event.stopImmediatePropagation()
					event.stopPropagation()
				}

				func(event)
			}
		}
		return eventHandlersRef.current[key]
	}

	const updateEventListeners = (action) => {
		if (!['add', 'remove'].includes(action)) return

		const triggerRefEventsDelay = ['mouseleave', 'touchmove', 'touchcancel', 'touchstart']
		const refEventsDelay = [...triggerRefEventsDelay, 'scroll']
		const refEvents = ['mouseenter', 'touchstart', 'click']

		const method = `${action}EventListener`
		const carousel = document.getElementsByClassName('ui-carousel__scene')[0]

		triggerRefEventsDelay.forEach((element) =>
			tooltipTriggerRef.current?.[method](element, wrapHandler(element, handleDelayedClose))
		)
		refEventsDelay.forEach((element) =>
			tooltipRef.current?.[method](element, wrapHandler(element, handleDelayedClose))
		)
		refEvents.forEach((element) =>
			tooltipRef.current?.[method](element, wrapHandler(element, handleCancelClose))
		)

		window[method]('scroll', handleScrollClose)
		carousel?.[method]('scroll', handleScrollClose)
	}

	const handleScrollClose = () => setOpen(false)

	const handleDelayedClose = () => {
		if (hideTimeoutRef.current) return

		const delay = desktopOnly ? 50 : 500

		hideTimeoutRef.current = setTimeout(() => {
			setOpen(false)
			hideTimeoutRef.current = null
		}, delay)
	}

	const handleCancelClose = () => {
		if (hideTimeoutRef.current) {
			clearTimeout(hideTimeoutRef.current)
			hideTimeoutRef.current = null
		}
	}

	const calculateElementPositions = () => {
		if (tooltipRef.current.style.left === 0) {
			return
		}

		height = tooltipRef.current.offsetHeight
		width = tooltipRef.current.offsetWidth
		position()
		arrowPosition()
	}

	useEffect(() => {
		if (isOpen) {
			if (!isSmallScreen) {
				calculateElementPositions()
				updateEventListeners('add')
			} else {
				setTooltipStyle({top: '', left: ''})
				setArrowStyle({top: '', left: ''})
			}
		} else {
			updateEventListeners('remove')
		}

		return () => updateEventListeners('remove')
	}, [isOpen, isSmallScreen])

	useEffect(() => {
		const mediaQuery = window.matchMedia(`(max-width: ${breakpoints['bp-small-medium'] - 1}px)`)

		const handleScreenChange = () => {
			setOpen(false)
			updateEventListeners('remove')
			handleCancelClose()
		}

		mediaQuery.addEventListener('change', handleScreenChange)

		return () => {
			mediaQuery.removeEventListener('change', handleScreenChange)
			updateEventListeners('remove')
		}
	}, [])

	const content = useMemo(() => {
		return {__html: children}
	}, [children])

	return (
		<span
			className={`tooltip-trigger ${triggerClass}`}
			{...{[handler]: handleOpen}}
			ref={tooltipTriggerRef}
		>
			{triggerContent}
			<div className="tooltip-trigger__content">
				{createPortal(
					<>
						<div
							className={`tooltip ${tooltipClass} ${isOpen ? 'tooltip--visible' : ''}`}
							ref={tooltipRef}
							style={tooltipStyle}
						>
							<div className="tooltip__content">
								{isSmallScreen && <Icon className="tooltip__icon" id="icon-info" />}
								<div dangerouslySetInnerHTML={content} />
								{isSmallScreen && (
									<button
										className="button button--outline-reverse tooltip__close-button"
										onClick={handleClose}
									>
										{intl.formatMessage({
											id: 'locale.global.close'
										})}
									</button>
								)}
							</div>

							{arrow && (
								<div
									className="tooltip__arrow"
									style={arrowStyle}
									ref={tooltipArrowRef}
								></div>
							)}
						</div>
						<div onClick={handleClose}>
							<Overlay
								isOpen={isSmallScreen ? isOpen : false}
								className="tooltip__overlay"
							/>
						</div>
					</>,
					document.getElementsByTagName('body')[0]
				)}
			</div>
		</span>
	)
}

Tooltip.propTypes = {
	tooltipClass: PropTypes.string,
	triggerClass: PropTypes.string,
	triggerContent: PropTypes.node,
	children: PropTypes.node,
	autoPositioning: PropTypes.bool,
	place: PropTypes.string,
	offset: PropTypes.number,
	spacing: PropTypes.number,
	arrow: PropTypes.bool,
	clickOnly: PropTypes.bool,
	desktopOnly: PropTypes.bool
}

export default Tooltip
