import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'
import {useIntl} from 'react-intl'

const CustomRefinements = ({
	filter,
	filterID,
	setRefinement,
	classExtensions,
	setOpenSecondaryPopUp,
	toggleFilter,
	selectedFilters
}) => {
	const intl = useIntl()
	const [isToggled, setToggle] = useState(false)
	const componentRef = useRef(null)
	const selectedFiltersAmount = selectedFilters?.length

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleMobileViewClick = () => {
		if (setRefinement && setOpenSecondaryPopUp) {
			setRefinement(filterID)
			setOpenSecondaryPopUp(true)
		}
	}

	return (
		<div
			className={
				'refinement refinement-custom ' + (isToggled && ' opened ') + classExtensions
			}
			onClick={() => {
				handleMobileViewClick()
			}}
			ref={componentRef}
		>
			<div className="refinement__listing">
				<ul>
					{filter.values.map((item) => {
						let subFilter
						for (let i = 0; i < item.values.length; i++) {
							if (item.values[i].value !== 'true') {
								continue
							}

							subFilter = item.values[i]
						}

						if (!subFilter) {
							return
						}

						const isSelected = selectedFilters.indexOf(item.name) > -1

						return (
							<li
								className={'refinement__list ' + (isSelected && ' selected')}
								key={item.name}
								data-gtm-value={item.name}
							>
								<a>
									<input
										className="input-checkbox"
										id={item.name + '-custom'}
										type="checkbox"
										checked={isSelected}
										onChange={() =>
											toggleFilter(subFilter, item.name, isSelected)
										}
									/>

									<label
										className="checkbox-label"
										htmlFor={item.name + '-custom'}
									>
										<span className="refinement__label-text">{item.name}</span>
										<span className="refinement__label-count">
											({subFilter.hits_count})
										</span>
									</label>
									{isSelected && (
										<Icon
											className="refinement__selected-icon"
											id={'icon-success-cyrcle'}
										/>
									)}
								</a>
							</li>
						)
					})}
				</ul>
			</div>

			<div className="refinement__title" onClick={() => setToggle(!isToggled)}>
				<span className="js-gtm-ref-name">
					{intl.formatMessage({
						id: 'search.search.refinement.title.custom'
					})}
				</span>
				{selectedFiltersAmount > 0 && (
					<span className="refinement__number">{selectedFiltersAmount.toString()}</span>
				)}
				<span className="refinement__title-arrow"></span>
			</div>
		</div>
	)
}

CustomRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	filterID: PropTypes.string,
	setRefinement: PropTypes.func,
	classExtensions: PropTypes.string,
	setOpenSecondaryPopUp: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default CustomRefinements
