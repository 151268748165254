export const getRefinementDefinitions = () => {
	return JSON.parse(window.ReactPreferences.REFINEMENT_DEFINITIONS)
}

export const separateCategoryFacets = (facets = [], isMobile) => {
	const categoryFacetName = getFacetDisplayName('category')
	const filteredFacets = isMobile
		? []
		: facets.filter((facet) => facet.name !== categoryFacetName)
	const categoryFacet = isMobile
		? facets
		: facets.filter((facet) => facet.name === categoryFacetName)

	return [categoryFacet, filteredFacets]
}

export const selectedCategoryRefinementsList = (refinementsList) => {
	var categoryRefList = []
	let str = ''

	for (let j = 0; j < refinementsList.length; j++) {
		if (refinementsList[j] !== '' && refinementsList[j + 1] !== '') {
			str += refinementsList[j]
			categoryRefList.push(str)
			str = ''
		} else if (refinementsList[j] == '') {
			str += '||'
		} else {
			str += refinementsList[j]
		}
	}

	return categoryRefList
}

export const filterOutPartialCategoryRefinements = (refinementsList, selectedRefinement) => {
	const categoryRefinementsList = selectedCategoryRefinementsList(refinementsList)
	var filteredRefinementsList = []

	categoryRefinementsList.forEach((refinementLine) => {
		if (selectedRefinement.indexOf(refinementLine) > -1) {
			return
		}

		const refinementList = refinementLine.split('||')
		let res = []

		refinementList.forEach((item, index) => {
			res.push(item)

			if (index < refinementList.length - 1) {
				res.push('')
			}
		})

		filteredRefinementsList = filteredRefinementsList.concat(res)
	})

	return filteredRefinementsList
}

export const buildFacetsQuery = () => {
	const refinementDefinitions = getRefinementDefinitions()

	// eslint-disable-next-line
		const combinedArray = Object.entries(refinementDefinitions).reduce((arr, [key, value]) => {
		return arr.concat(value)
	}, [])

	return combinedArray.join(',')
}

export const getFacetDisplayName = (filter) => {
	const refinementDefinitions = getRefinementDefinitions()

	return refinementDefinitions[filter] || filter
}

export const getFacetIDByDisplayName = (filterName) => {
	const refinementDefinitions = getRefinementDefinitions()

	return Object.keys(refinementDefinitions).find(
		(key) => refinementDefinitions[key] === filterName
	)
}

export const filterCustomFacets = (filters) => {
	const refinementDefinitions = getRefinementDefinitions()
	let customFilters = []

	if (!refinementDefinitions.custom) {
		return customFilters
	}

	customFilters = Object.keys(filters).filter((key) => refinementDefinitions.custom.includes(key))

	return customFilters
}

export const groupFacets = (filters) => {
	const refinementDefinitions = getRefinementDefinitions()
	let filterGroups = []

	if (!filters) {
		return filterGroups
	}

	let customFilters = {
		name: 'custom',
		values: []
	}
	let hasCustomFilters = false

	for (let i = 0; i < filters.length; i++) {
		const item = filters[i]
		const name = item.name

		if (refinementDefinitions.custom.indexOf(name) > -1) {
			hasCustomFilters = true
			customFilters.values.push(item)
		} else {
			filterGroups.push(item)
		}
	}

	if (hasCustomFilters) {
		filterGroups.splice(1, 0, customFilters)
	}

	return filterGroups
}

export const excludeCategoryRefinements = (refinementsList, value) => {
	var categoryRefinementsList = selectedCategoryRefinementsList(refinementsList)
	var newCategoryRefinementsList = []

	for (let j = 0; j < categoryRefinementsList.length; j++) {
		let item = categoryRefinementsList[j]

		if (item == value || item.indexOf(value) !== 0) {
			newCategoryRefinementsList.push(item)
			continue
		}

		let filtersToReduce = value.split('||').length

		if (filtersToReduce == 1) {
			continue
		}

		let spl = item.split('||')
		spl.splice(filtersToReduce * -1)
		item = spl.join('||')

		newCategoryRefinementsList.push(item)
	}

	return [...new Set(newCategoryRefinementsList)]
}

export const sortPriceRefinements = (values) => {
	values.sort((a, b) => {
		const aValue = a.value.split('|')[0]
		const bValue = b.value.split('|')[0]
		return aValue - bValue
	})

	return values
}

export const sortRatingRefinements = (values) => {
	values.sort((a, b) => {
		const aValue = extractRatingNumber(a.value)
		const bValue = extractRatingNumber(b.value)
		return bValue - aValue
	})

	return values
}

export const extractRatingNumber = (value) => {
	return value.match(/\d+(?:\.\d+)?/)[0]
}
