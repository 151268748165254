import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import Icon from '../../../components/icon'
import {useSortUrls} from '../../../hooks/use-sort-urls'
import {useIntl} from 'react-intl'
import {scrollToElement} from '../../../utils/utils'

const Sort = ({basePath}) => {
	const intl = useIntl()
	var sortingOptions = [
		{
			id: 'best-matches'
		},
		{
			id: 'title:asc'
		},
		{
			id: 'title:desc'
		},
		{
			id: 'price:asc'
		},
		{
			id: 'price:desc'
		}
	]

	const urlParams = new URLSearchParams(basePath)
	const sortQuery = urlParams.get('sort') || sortingOptions[0].id
	const componentRef = useRef(null)
	const navigate = useNavigate()
	const sortUrls = useSortUrls({options: sortingOptions})
	const [toggle, setToggle] = useState(false)

	const handleListCountryClick = (href) => {
		navigate(href.replace(/(offset)=(\d+)/i, '$1=0'))
		setToggle(!toggle)
		scrollToElement('.search-results')
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className="sort-by" ref={componentRef}>
			<div className={'select-wrapper ' + (toggle && ' opened')}>
				<div className="select-wrapper__options">
					<ul>
						{sortUrls.map((href, index) => {
							const isSelected = sortQuery == sortingOptions[index]?.id
							return (
								<li
									key={href}
									value={href}
									className={isSelected ? 'selected' : ''}
									onClick={() => handleListCountryClick(href)}
								>
									{intl.formatMessage({
										id: 'search.search.sort.' + sortingOptions[index]?.id
									})}
									{isSelected && (
										<Icon
											className={'select-wrapper__selected-icon'}
											id={'icon-success-cyrcle'}
										/>
									)}
								</li>
							)
						})}
					</ul>
				</div>

				<div className="select-wrapper__select" onClick={() => setToggle(!toggle)}>
					<label className="select-wrapper__label">
						{intl.formatMessage({id: 'locale.global.paginginformation.sortby'})}
					</label>
					<span className="select-wrapper__arrow"> </span>
				</div>
			</div>
		</div>
	)
}

Sort.propTypes = {
	basePath: PropTypes.string,
	toggleFilter: PropTypes.func
}

export default Sort
